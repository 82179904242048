.parallax-background{


    background:url("../public/images/college-75535_1920\ -\ Copy\ \(3\).jpg");
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    background-repeat: no-repeat;
}

.small-boxes{
    background-color:white;
    box-shadow: -15px 15px 30px rgb(35, 34, 34);
}