.videoWrap {
    position: relative;
    overflow: hidden;
    width: 100%;
    min-height: 100vh;
}

video {
    min-width: 100%;
    min-height: 100vh;
    object-fit: cover;
    position: fixed;
    top: 0;
    z-index: -2;
}

.topShadow {
    position: fixed;
    width: 100%;
    height: 20rem;
    background: linear-gradient(0deg, transparent, rgba(0, 0, 0, .9)), linear-gradient(0deg, transparent 10%, rgba(0, 0, 0, .6));
    z-index: -1;
}

.icon {
    display: inline;
    transition: 0.3s ease-out;
    margin-left: 0.5rem;
    font-size: 2rem;
    transform: translateY(-0.05rem);
}

.explore {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #ff7f00;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
    padding-top: 0.2rem;
    cursor: pointer;
    z-index: 0;
}

.explore .icon {
    font-size: 1.2rem;
}

.explore:hover .icon {
    transform: translateY(0.2rem);
}

.navBar {
    z-index: 999;
    position: fixed;
    max-width: 70rem;
    left: 50%;
    transform: translateX(-50%);
}

@media (max-width:1000px) {
    .navBar {
        height: 100%;
        max-width: 100%;
        width: 100vw;
        left: unset;
        transform: unset;
    }

    .topShadow {
        height: calc(min(9.6vw, 3.5rem) + 7rem);
    }
}