.navDesktop {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 60rem;
}

.topGap {
    position: relative;
    height: 2rem;
}

.navWrap {
    max-width: 60rem;
    margin: 0 auto;
    transition: 0.7s;
}

.topBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.2rem;
}

.iiitLogo {
    width: 30rem;
    z-index: 2;
}

.nameAddr {
    text-align: end;
    padding-top: 1rem;
    font-size: 0.8rem;
    color: white;
    opacity: 0.6;
}

.nameAddr>div:first-of-type {
    font-weight: bold;
}

.mainmenu ul li {
    display: inline-block;
    padding: 1.1rem 0;
    position: relative;
}

.mainmenu ul li a {
    position: relative;
}

.mainmenu ul li ul {
    background: white none repeat scroll 0 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
    left: -15%;
    opacity: 0;
    position: absolute;
    text-align: left;
    top: 135%;
    transition: all 0.3s ease 0s;
    visibility: hidden;
    width: 200px;
    z-index: 999;
    padding: 0;
    border-radius: 7px;
}

.menu>li:last-of-type>ul {
    left: unset;
    right: -15%;
}

.mainmenu ul li:hover>ul {
    opacity: 1;
    top: 90%;
    visibility: visible;
}

.mainmenu ul li ul li {
    display: block;
    margin: 0;
    padding: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.03);
    transition: all 0.3s ease 0s;
    border-radius: 7px;
    transition: .2s ease-in;
}

.mainmenu ul li ul li:hover {
    background: #f1f1f1;
}

.mainmenu ul li ul li a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 15px;
    text-transform: capitalize;
}

.mainmenu ul li ul li:first-child {
    border-top: none;
}

.mainmenu ul li ul li:hover>a {
    padding-left: 15px;
}

.mainmenu ul li ul li a:before {
    display: none;
}

.mainmenu ul li ul li>ul {
    left: 100%;
    margin-top: -1px;
    opacity: 0;
    position: absolute;
    top: 45%;
    visibility: hidden;
}

.mainmenu ul li ul li:hover>ul {
    top: 0;
}

.mainmenu ul li ul li:last-of-type>ul {
    top: unset;
    bottom: 45%;
}

.mainmenu ul li ul li:last-of-type:hover>ul {
    top: unset;
    bottom: 0;
}

.mainmenu.text-right span {
    float: right;
}

.menu {
    display: flex;
    justify-content: space-between;
}

.menu>li>a {
    font-weight: bold;
    transition: color .3s ease-in;
}

.menu>li>a::after {
    content: "";
    position: absolute;
    width: 130%;
    height: 2.35rem;
    top: 0%;
    left: -15%;
    border-radius: 7px;
    background-color: #ff7f00;
    transform: scaleY(0);
    transition: all .3s ease-in;
    z-index: -1;
}

.menu>li:hover>a::after {
    transform: scaleY(1);
    transition: all .3s ease-out;
    top: -50%;
}

.icon {
    display: inline;
    transform: translateY(-0.1rem);
    margin-left: 0.2rem;
}

.mainmenu {
    max-width: 100%;
    color: white;
}

.options {
    color: black;
}

.scrolledBg {
    width: 100vw;
    position: fixed;
    background: rgb(28, 28, 28);
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.7s;
    z-index: -2;
}