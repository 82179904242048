.slide {
    text-align: center;
    height: auto;
    max-height: 500px;
    line-height: 500px;
    /* background: #364d79; */
    overflow: hidden;
    /* background-image: linear-gradient(to bottom right, #ffbb77, #ff6f00); */
}
.slide:hover {
    cursor: grab;
}
.swiper-button-next,
.swiper-button-prev {
    padding: 10px;
    width: auto;
    height: 42px;
    /* margin-top: 0; */
    background-color: rgba(0, 0, 0, 0.567);
    border-radius: 50%;
    /* color: white; */
    /* background: #ffff url("./right-chevron-arrow-round-icon.svg") no-repeat; */
    background:
        rgb(255, 255, 255) 
        url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" shape-rendering="geometricPrecision" text-rendering="geometricPrecision" image-rendering="optimizeQuality" fill-rule="evenodd" clip-rule="evenodd" viewBox="0 0 512 512"><path d="M0 256c0 70.69 28.66 134.7 74.98 181.02C121.3 483.34 185.32 512 256 512c70.69 0 134.7-28.66 181.02-74.98C483.34 390.7 512 326.69 512 256c0-70.69-28.66-134.69-74.98-181.02C390.7 28.66 326.69 0 256 0 185.32 0 121.3 28.66 74.98 74.98 28.66 121.31 0 185.31 0 256zm227.91-109.04L336.94 256 227.91 365.04l-40.52-40.51 68.51-68.52-68.52-68.52 40.53-40.53z"/></svg>') 
        no-repeat;
    color: rgba(0, 0, 0, 0);
    opacity: .7;
}

.swiper-button-prev {
    transform: scale(-1);
}
.custom-bg {
    /* background-image: linear-gradient(to bottom right, #ffc58b, #e74900); */
    background-image: url("../../assets/spiralspiral.png");
    background-size: contain;
    color: #2f2f2f, 
}