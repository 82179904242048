@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital@1&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

.Font{
    font-family: 'IBM Plex Sans', sans-serif;

}
.rotate_01{
    -webkit-transition: -webkit-transform 1s;
}
.rotate_01:hover{
    -webkit-transform: rotate(360deg) translateZ(0);
}
.wraper{
    display:block;
}
.wraper:hover{
    display:block;
}
.FixedNav{
   
     transition:.4s ease-in-out;
}
.overlay{
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.4);
    z-index:-1;
}
h1{
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 800;   
    font-style: normal; 
}
h2{
    font-family: 'IBM Plex Sans', sans-serif;
    /* font-weight: 500;  */
    font-style:italic; 

}
h5{
    font-family: 'Poppins', sans-serif;
    /* font-weight: 800;  */
}
#aboutText{
    height: 100vh;
    overflow: overlay;
}

*{
    scroll-behavior: smooth;
}